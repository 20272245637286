import * as React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import MyBlock from '../components/block'
import { StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const DestinationPage = () => {
	return (
		<Layout pageTitle="Destinations">
			<MyBlock title="Destinations" icon="map-marked-alt" bg="white">
				<p> <FACES/> works with schools and teachers in many states in the United States.</p>
				<h4 className="text-center font-weight-bold ">Map of states :</h4>
				<div id="map-usa" className="svg-container"><img src="/map-usa.svg"></img></div>
				<h4 className="text-center font-weight-bold mb-2"> Map legend :</h4>
				<ul>
					<li className="row"> <div className="mr-2" style={{width: "3em", height: "2em", backgroundColor: "#27AE60", borderRadius: "0.5em"}}></div><p> States where we are present</p></li>
					<li className="row"> <div className="mr-2" style={{width: "3em", height: "2em", backgroundColor: "#3498DB", borderRadius: "0.5em"}}></div><p> Sates where we are working on a partnership</p></li>
					<li className="row"> <div className="mr-2" style={{width: "3em", height: "2em", backgroundColor: "#E74C3C", borderRadius: "0.5em"}}></div><p> Sates where we have contacts</p></li>
					<li className="row"> <div className="mr-2" style={{width: "3em", height: "2em", backgroundColor: "#2C3E50", borderRadius: "0.5em"}}></div><p> States where we are not present</p></li>
				</ul>
		</MyBlock>
		</Layout >
	)
}


const FACES = () => {
	return (
		<span style={{ color: "#003053", fontWeight: "bold" }}>FACES</span>
	)
}

export default DestinationPage